import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  text: string | JSX.Element;
  title?: string | JSX.Element;
  isWithLoader?: boolean;
};

const FrozenBlock: FC<Props> = ({ text, title, isWithLoader }) => {
  return (
    <div className={styles.frozen_block}>
      {title && (
        <Typography
          variant={Typographies.TITLE_MEDIUM}
          component="p"
          sx={{
            fontWeight: '500',
            marginBottom: '20px',
          }}
        >
          {title}
        </Typography>
      )}

      <div className={styles.frozen_block__flex}>
        <Typography
          variant={Typographies.TITLE_MEDIUM}
          component="p"
          sx={{
            fontWeight: '400',
          }}
        >
          {text}
        </Typography>
        {isWithLoader && (
          <Icon
            className={styles.frozen_block__icon}
            icon="svg-spinners:tadpole"
          />
        )}
      </div>
    </div>
  );
};

export default FrozenBlock;
