import React, { FC } from 'react';

import TestedFomoModal from '~/modules/settings/components/modals/FomoModal/variants/Tested';

type Props = {
  subscriptionWordsLeft: number;
  subscriptionName: string;
  isSubscriptionUnlimited: boolean;
  onKeepPlan: () => void;
  onCancelPlan: () => void;
};

const FomoModal: FC<Props> = (props) => {
  return <TestedFomoModal {...props} />;
};

export default FomoModal;
