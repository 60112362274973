import React, { FC, Fragment, useMemo } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const COLUMN_COUNT = 3;

type ItemType = {
  Icon: FC<{ className?: string }>;
  text: string;
  onClick?: () => void;
};

type Props = {
  items: ItemType[];
};

const PlanDetails: FC<Props> = ({ items }) => {
  const columnsData = useMemo(() => {
    const columnCount = Math.round(items.length / COLUMN_COUNT);
    const data: ItemType[][] = [];
    for (let i = 0; i < items.length; i += columnCount) {
      const chunk = items.slice(i, i + columnCount);
      data.push(chunk);
    }
    return data;
  }, [items]);

  return (
    <div className={styles.container}>
      <Typography
        variant={Typographies.HEADLINE_SMALL}
        component="h3"
        className={styles.title}
      >
        Your Plan Details
      </Typography>

      <div className={styles.grid}>
        {columnsData.map((group, index) => (
          <div key={index} className={styles.grid__column}>
            {group.map(({ Icon, text, onClick }, index) => (
              <Fragment key={index}>
                <div
                  onClick={onClick}
                  className={clsx(styles.grid__item, {
                    [styles['grid__item--pointer']]: onClick,
                  })}
                >
                  <Icon className={styles.grid__item_icon} />

                  <Typography variant={Typographies.TITLE_SMALL} component="p">
                    {text}
                  </Typography>
                </div>
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanDetails;
