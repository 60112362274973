import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DoneImg from '~/assets/images/done_v2.webp';
import DonePreviewImg from '~/assets/images/done_v2_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  pauseDuration: number;
  onClose: () => void;
};

const PauseSuccessModal: FC<Props> = ({ pauseDuration, onClose }) => {
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const { t } = useTranslation('modal');

  useEffect(() => {
    analytics.trackEvent('holiday pause - pause success modal view');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={DoneImg} preview={DonePreviewImg} />
      </div>

      <Typography
        variant={Typographies.TITLE_LARGE}
        component="div"
        className={styles.title}
      >
        {t('pause_sub_success.title', {
          duration: pauseDuration,
          defaultValue:
            'Your plan is successfully paused for {{duration}} weeks',
        })}
      </Typography>

      <Typography
        variant={Typographies.BODY_LARGE}
        component="div"
        className={styles.text}
      >
        {t(
          'pause_sub_success.text',
          'You can unpause it in Subscription section on your profile page',
        )}
      </Typography>

      <Typography
        variant={Typographies.BODY_MEDIUM}
        component="div"
        className={styles.subtext}
      >
        {t(
          'pause_sub_success.subtext',
          'You won’t be charged during this period',
        )}
      </Typography>

      <div className={styles.buttons_container}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          fullWidth={!isDesktop}
        >
          {t('general:got_it', 'Got it')}
        </Button>
      </div>
    </div>
  );
};

export default PauseSuccessModal;
