import React, { FC } from 'react';

import { Typography } from '@mui/material';

import Button from '~/components/atoms/buttons/Button';
import LoadingButton from '~/components/atoms/buttons/LoadingButton';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { SubProcessingStatus } from '~/modules/settings/views/Subscription';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';
import { SUBSCRIPTION_STATUS, SubscriptionType } from '~/types/subscription';

type Props = {
  subscription: SubscriptionType;
  subProcessingStatus: SubProcessingStatus;
  text?: string;
  disabled?: boolean;
  onCancelSubClick: () => void;
  onRestoreSubClick: () => Promise<void>;
};

const SubManager: FC<Props> = ({
  subscription,
  subProcessingStatus,
  text = 'Cancel Plan',
  disabled,
  onCancelSubClick,
  onRestoreSubClick,
}) => {
  const { is_stripe_user } = useAppSelector((state) => state.profile);
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const isProcessing = subProcessingStatus === SubProcessingStatus.PENDING;

  return (
    <div>
      {[
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.REDEMPTION,
        SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING,
        SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_FAILED,
      ].includes(subscription.status) && (
        <LoadingButton
          disabled={isProcessing || disabled}
          variant="outlined"
          color="secondary"
          fullWidth={!isDesktop}
          onClick={onCancelSubClick}
          loading={isProcessing}
        >
          {text}
        </LoadingButton>
      )}

      {!is_stripe_user &&
        [
          SUBSCRIPTION_STATUS.CANCEL_PENDING,
          SUBSCRIPTION_STATUS.CANCELLED,
        ].includes(subscription.status) && (
          <>
            <Typography
              variant={Typographies.TITLE_MEDIUM}
              component="p"
              sx={{
                marginBottom: '20px',
              }}
            >
              {`If you change your mind, you can always restore subscription ${
                subscription.is_trial &&
                subscription.status === SUBSCRIPTION_STATUS.CANCEL_PENDING
                  ? ''
                  : '(' +
                    subscription.subscription_name +
                    ' - $' +
                    subscription.subscription_price +
                    ')'
              }`}
            </Typography>

            <Button variant="contained" onClick={onRestoreSubClick}>
              Restore Plan
            </Button>
          </>
        )}
    </div>
  );
};

export default SubManager;
