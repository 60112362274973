import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import Button from '~/components/atoms/buttons/Button';
import { useModalContext } from '~/contexts/modal/ModalContext';
import ChooseSubscriptionModal, {
  ReasonType,
} from '~/modules/settings/components/modals/ChooseSubscriptionModal';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

import styles from './styles.module.scss';

type Props = {
  status: SUBSCRIPTION_STATUS;
};

const Redemption: FC<Props> = ({ status }) => {
  const { user_subscription, is_stripe_user } = useAppSelector(
    (state) => state.profile,
  );
  const { handleOpenModal } = useModalContext();

  const handleRestoreSubscriptionHard = async (): Promise<void> => {
    analytics.trackEvent('subscription - redemption issue click', {
      sub: user_subscription.subscription_name,
      place: 'subscription_page',
    });
    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,

      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.REDEMPTION}
        />
      ),
    });
  };

  const handleRedemptionFailed = async (): Promise<void> => {
    analytics.trackEvent('subscription - failed redemption issue click', {
      sub: user_subscription.subscription_name,
      place: 'subscription_page',
    });
    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,

      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.REDEMPTION_FAILED}
        />
      ),
    });
  };

  return (
    <div
      className={clsx(styles.redemption, {
        [styles.is_processing]:
          status === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING,
      })}
    >
      {status === SUBSCRIPTION_STATUS.REDEMPTION && (
        <>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.redemption__title}
            component="p"
          >
            <Icon icon="ph:warning-fill" color={colors.warning_60} width="24" />
            <span>The payment could not be processed</span>
          </Typography>

          {!is_stripe_user && (
            <Button
              color="primary"
              variant="contained"
              sx={{ '&&': { marginTop: '20px' } }}
              onClick={handleRestoreSubscriptionHard}
            >
              Fix Payment Issue
            </Button>
          )}
        </>
      )}

      {status === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING && (
        <>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.redemption__title}
            component="p"
          >
            <Icon
              color={colors.primary_40}
              width="24"
              icon="svg-spinners:tadpole"
            />
            <span>Fixing payment issue...</span>
          </Typography>

          {!is_stripe_user && (
            <Button
              color="primary"
              variant="contained"
              disabled
              sx={{
                '&&': {
                  marginTop: '20px',
                  color: '#838799',
                  background: '#C3C9E4',
                },
              }}
            >
              Fix Payment Issue
            </Button>
          )}
        </>
      )}

      {status === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_FAILED && (
        <>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.redemption__title}
            component="p"
          >
            <Icon icon="ph:warning-fill" color={colors.warning_60} width="24" />
            <span>The payment could not be processed</span>
          </Typography>

          <Typography
            variant={Typographies.BODY_MEDIUM}
            sx={{ '&&': { marginTop: '20px' } }}
            component="p"
          >
            Current payment method is not working
          </Typography>

          {!is_stripe_user && (
            <Button
              color="primary"
              variant="contained"
              sx={{ '&&': { marginTop: '20px' } }}
              onClick={handleRedemptionFailed}
            >
              Update Payment Method
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default Redemption;
