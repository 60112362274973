import React, { FC, useState } from 'react';

import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '~/components/atoms/buttons/LoadingButton';
import analytics from '~/services/analytics';

import styles from './styles.module.scss';

type Props = {
  onClick: () => Promise<void>;
  disabled?: boolean;
};

const UnpauseSuggestionButton: FC<Props> = ({ onClick, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('general');

  const handleClick = async (): Promise<void> => {
    try {
      setIsLoading(true);
      analytics.trackEvent('holiday pause - unpause sub click');
      await onClick();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      loading={isLoading}
      disabled={disabled || isLoading}
      color="gradient"
      variant="contained"
      startIcon={
        <Icon
          icon="material-symbols:rocket-launch-outline"
          width="24"
          height="24"
        />
      }
      onClick={handleClick}
      className={styles.button}
    >
      {t('unpause_plan', 'Unpause Plan')}
    </LoadingButton>
  );
};

export default UnpauseSuggestionButton;
