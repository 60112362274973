// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YsYsB1wxV1Fn_V7YlW9d{padding:10px 16px !important;color:#fff !important}@media(max-width: 1023px){.YsYsB1wxV1Fn_V7YlW9d{padding:16px 24px !important}}`, "",{"version":3,"sources":["webpack://./src/modules/cancellation/features/pause/components/UnpauseSuggestionButton/styles.module.scss","webpack://./src/styles/_breakpoints.scss"],"names":[],"mappings":"AAIA,sBACE,4BAAA,CACA,qBAAA,CCQE,0BDVJ,sBAKI,4BAAA,CAAA","sourcesContent":["@import \"src/styles/mixins\";\n@import \"src/styles/breakpoints\";\n@import \"src/styles/colors\";\n\n.button {\n  padding: 10px 16px !important;\n  color: $white !important;\n\n  @include breakpoint(md) {\n    padding: 16px 24px !important;\n  }\n}\n","@mixin breakpoint($class) {\n  @if $class == xxs {\n    @media (max-width: 560px) {\n      @content;\n    }\n  }\n\n  @if $class == xs {\n    @media (max-width: 719px) {\n      @content;\n    }\n  }\n\n  @if $class == md {\n    @media (max-width: 1023px) {\n      @content;\n    }\n  }\n\n  @if $class == l {\n    @media (max-width: 1279px) {\n      @content;\n    }\n  }\n\n  @if $class == xl {\n    @media (max-width: 1439px) {\n      @content;\n    }\n  }\n\n  @if $class == xxl {\n    @media (max-width: 1919px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `YsYsB1wxV1Fn_V7YlW9d`
};
export default ___CSS_LOADER_EXPORT___;
