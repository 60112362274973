import React, { FC, useRef } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import CountUp from 'react-countup';

import useIntersectionObserver from '~/hooks/useIntersectionObserver';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

export type StatisticsItemType = {
  id: string;
  icon: JSX.Element;
  amount: number;
  title: string;
  text: string;
};

type Props = {
  item: StatisticsItemType;
};

const StatisticsItem: FC<Props> = ({ item }) => {
  const itemsRef = useRef<HTMLDivElement | null>(null);
  const isIntersected = useIntersectionObserver(itemsRef, {}, true);

  return (
    <div
      className={clsx(styles.item, styles[item.id])}
      key={item.id}
      ref={itemsRef}
    >
      <div className={styles.item__result}>
        <div className={styles.item__icon}>{item.icon}</div>

        <Typography
          variant={Typographies.HEADLINE_MEDIUM}
          component="h4"
          className={styles.item__amount}
        >
          {isIntersected && (
            <>
              <CountUp start={0} end={item.amount} duration={2} />{' '}
              {item.id === 'time' ? 'm' : ''}
            </>
          )}
        </Typography>
      </div>

      <Typography
        component="h4"
        className={styles.item__title}
        sx={{
          typography: {
            xs: Typographies.TITLE_MEDIUM,
            xxl: Typographies.TITLE_LARGE,
          },
        }}
      >
        {item.title}
      </Typography>

      <Typography
        variant={Typographies.BODY_MEDIUM}
        component="p"
        className={styles.item__text}
      >
        {item.text}
      </Typography>
    </div>
  );
};

export default StatisticsItem;
