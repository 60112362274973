import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';

import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const benefits = [
  {
    emoji: '✍️',
    text: 'Words Generated:',
    value: '100,000+',
  },
  {
    emoji: '📍',
    text: 'Tasks Completed:',
    value: '500+',
  },
  {
    emoji: '🕗',
    text: 'Hours Saved:',
    value: '~80+',
  },
];

type Props = {
  subscriptionWordsLeft: number;
  subscriptionName: string;
  isSubscriptionUnlimited: boolean;
  onKeepPlan: () => void;
  onCancelPlan: () => void;
};

const TestedFomoModal: FC<Props> = ({
  subscriptionName,
  onKeepPlan,
  onCancelPlan,
}) => {
  useEffect(() => {
    analytics.trackEvent('subscription - fomo modal');
  }, []);

  const handleKeepClick = (): void => {
    onKeepPlan();
    analytics.trackEvent('subscription - not verify cancel sub', {
      sub: subscriptionName,
    });
  };

  return (
    <div>
      <Typography
        variant={Typographies.TITLE_LARGE}
        component="h4"
        className={styles.title}
        sx={{ '&&': { marginBottom: '32px', fontWeight: 500 } }}
      >
        Are you sure you want to cancel Justdone plan?
      </Typography>

      <ModalContentText sx={{ mb: '32px' }}>
        Each month, users with this subscription typically:
      </ModalContentText>

      <div className={styles.benefits}>
        {benefits.map((ben) => (
          <div className={styles.benefit} key={ben.value}>
            <Typography
              variant={Typographies.HEADLINE_SMALL}
              component="h4"
              className={styles.benefit__icon}
            >
              {ben.emoji}
            </Typography>

            <Typography
              sx={{
                typography: {
                  xs: Typographies.TITLE_SMALL,
                  lg: Typographies.TITLE_MEDIUM,
                },
              }}
              component="h4"
              className={styles.benefit__title}
            >
              {ben.text}
            </Typography>

            <Typography
              sx={{
                typography: {
                  xs: Typographies.HEADLINE_SMALL,
                  lg: Typographies.HEADLINE_MEDIUM,
                },
              }}
              component="p"
              className={styles.benefit__amount}
            >
              {ben.value}
            </Typography>
          </div>
        ))}
      </div>

      <div className={styles.buttons_container}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          fullWidth
          onClick={onCancelPlan}
        >
          Cancel Plan
        </Button>

        <Button
          className={styles.button}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleKeepClick}
        >
          Keep Plan
        </Button>
      </div>
    </div>
  );
};

export default TestedFomoModal;
