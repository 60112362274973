import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';

import analytics from '~/services/analytics';

import styles from './styles.module.scss';

export enum PlanVariants {
  UNLIMITED = 'unlimited',
  LIMITED = 'limited',
}

const plans = [
  {
    variant: PlanVariants.UNLIMITED,
    text: 'Unlimited',
  },
  {
    variant: PlanVariants.LIMITED,
    text: 'Limited',
  },
];

type Props = {
  onClick: (type: PlanVariants) => void;
  currentVariant: PlanVariants;
};

const Toggler: FC<Props> = ({ currentVariant, onClick }) => {
  const handleButtonClick = (type: PlanVariants) => (): void => {
    analytics.trackEvent('subscription - click sub type selector', { type });
    onClick(type);
  };

  return (
    <div className={styles.toggler}>
      {plans.map((plan) => {
        const isActive = currentVariant === plan.variant;

        return (
          <button
            key={plan.variant}
            className={clsx(styles.button, {
              [styles.is_active]: isActive,
            })}
            onClick={handleButtonClick(plan.variant)}
          >
            {isActive && <Icon icon="mdi:tick" className={styles.icon} />}
            <span>{plan.text}</span>
          </button>
        );
      })}
    </div>
  );
};

export default Toggler;
