import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

import Api from '~/Api';
import IconButton from '~/components/atoms/buttons/IconButton';
import Loader from '~/components/atoms/Loader';
import { useSnackBarContext } from '~/features/snackbar/SnackbarContext';
import useErrors from '~/hooks/useErrors';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import useQueryParams from '~/hooks/useQueryParams';
import { ReactComponent as CatImgDesktop } from '~/modules/cancellation/assets/crying_cat_desktop.svg';
import { ReactComponent as CatImgMobile } from '~/modules/cancellation/assets/crying_cat_mobile.svg';
import { CancellationPath } from '~/modules/cancellation/constants';
import { SubscriptionPath } from '~/modules/settings/constants';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';
import { SUBSCRIPTION_STATUS, SubscriptionType } from '~/types/subscription';

import FormMultiple from './screens/FormMultiple';
import Success from './screens/Success';
import styles from './styles.module.scss';
import { CancellationFormDataType } from './types';

export enum SCREEN {
  CANCEL_FORM = 'cancel_form',
  SUCCESS = 'success',
}

const CancellationV3: FC = () => {
  const { state } = useLocation();
  const { getQueryParams } = useQueryParams();
  const currentScreen = getQueryParams().screen as SCREEN;

  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState<SubscriptionType | null>(
    null,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const scrollElemRef = useRef<HTMLDivElement | null>(null);

  const profile = useAppSelector((state) => state.profile);
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);
  const { showSnackbar } = useSnackBarContext();
  const { loadAndSetActualProfileData } = useActions();
  const { reportUserErrors, reportProfileError } = useErrors();

  useEffect(() => {
    if (!currentScreen) {
      navigate(
        { pathname: CancellationPath, search: `?screen=${SCREEN.CANCEL_FORM}` },
        { replace: true },
      );
    }
  }, [location, currentScreen]);

  useEffect(() => {
    const isRedirectToSubPage = [
      SUBSCRIPTION_STATUS.CANCELLED,
      SUBSCRIPTION_STATUS.CANCEL_PENDING,
    ].includes(profile.user_subscription.status);

    if (isRedirectToSubPage && currentScreen !== SCREEN.SUCCESS) {
      navigate(SubscriptionPath);
    }
  }, [profile, currentScreen]);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const subData = await Api.getSubscription();

        setSubscription(subData);
      } catch (e: any) {
        reportUserErrors({
          error: e,
          method: 'getSubscription',
          userMessage:
            'Error fetching subscription details. Refresh or try later or',
        });
      }
    })();
  }, []);

  const handleGoBack = (): void => {
    analytics.trackEvent('subscription - ac.break cancel flow');
    navigate(state?.from || SubscriptionPath);
  };

  const cancelSubscription = async (
    data: CancellationFormDataType,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      analytics.setUserProperties({
        cancel_reason: data.reason || null,
        cancel_sub_reason: data.sub_reason || null,
        cancel_related_to: data.related_to || null,
      });
      analytics.trackEvent('subscription - ac.confirm cancel');

      await Api.cancelSubscription(data, profile.id);

      analytics.trackEvent('subscription - ac.cancel exit feedback', {
        reason: data.reason,
        sub_reason: data.sub_reason,
        related_to: data.related_to,
        feedback: data.feedback,
      });

      showSnackbar('Subscription Canceled', 'info');

      window.setTimeout(async () => {
        try {
          await loadAndSetActualProfileData();
        } catch (e: any) {
          reportProfileError(e);
        }
      }, 3500);

      navigate(
        { pathname: CancellationPath, search: `?screen=${SCREEN.SUCCESS}` },
        {
          state: {
            flow: 'cancel',
          },
        },
      );
    } catch (e: any) {
      const errorMessage = e.error || e.message;

      if (errorMessage === 'Subscription already cancelled') {
        showSnackbar('Subscription already cancelled', 'info');
        navigate(SubscriptionPath);
        return;
      }

      reportUserErrors({
        error: e,
        method: 'cancelSubscription',
        userMessage:
          'Subscription cancellation failed. Try again or contact support or',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const SCREENS: Record<SCREEN, ReactElement<any, any>> | null = useMemo(() => {
    if (!subscription) {
      return null;
    }

    return {
      [SCREEN.CANCEL_FORM]: (
        <FormMultiple
          title="Why are you canceling subscription?"
          onFormSubmit={cancelSubscription}
          onFormRefuse={handleGoBack}
        />
      ),
      [SCREEN.SUCCESS]: <Success />,
    };
  }, [subscription]);

  return (
    <>
      <Loader isLoading={isLoading} />

      {subscription && currentScreen ? (
        <div className={styles.container}>
          {currentScreen !== SCREEN.SUCCESS && (
            <div className={styles.header}>
              <Typography
                className={styles.title}
                component="h3"
                sx={{
                  typography: {
                    xs: Typographies.HEADLINE_MEDIUM,
                    lg: Typographies.HEADLINE_LARGE,
                  },
                }}
              >
                We will miss you! ❤️
              </Typography>

              {isDesktop && (
                <IconButton
                  size="small"
                  className={styles.button}
                  onClick={handleGoBack}
                >
                  <Icon
                    icon="material-symbols:close"
                    className={styles.button__icon}
                  />
                </IconButton>
              )}
            </div>
          )}

          <div
            className={clsx(styles.main, {
              [styles.final_screen]: currentScreen === SCREEN.SUCCESS,
            })}
          >
            {currentScreen !== SCREEN.SUCCESS && (
              <div className={styles.image}>
                {!isDesktop ? <CatImgMobile /> : <CatImgDesktop />}
              </div>
            )}

            <div className={styles.content} ref={scrollElemRef}>
              {SCREENS && SCREENS[currentScreen]}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CancellationV3;
