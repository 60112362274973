import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { ReactComponent as TasksIcon } from '~/components/molecules/FomoList/assets/tasks.svg';
import { ReactComponent as TimeIcon } from '~/components/molecules/FomoList/assets/time.svg';
import { ReactComponent as WordsIcon } from '~/components/molecules/FomoList/assets/words.svg';
import StatisticsItem, {
  StatisticsItemType,
} from '~/modules/settings/views/Subscription/Statistics/StatisticsItem';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  tasksCompleted: number;
  wordsGenerated: number;
  timeSaved: number;
};

const Statistics: FC<Props> = ({
  wordsGenerated,
  tasksCompleted,
  timeSaved,
}) => {
  const statList: StatisticsItemType[] = [
    {
      id: 'words',
      icon: <WordsIcon />,
      amount: wordsGenerated,
      title: 'Total Words Generated',
      text: 'Number of words generated across all Justdone tools for the entire period',
    },
    {
      id: 'tasks',
      icon: <TasksIcon />,
      amount: tasksCompleted,
      title: 'Tasks Completed',
      text: 'Number of completed requests in all Justdone tools for the entire period',
    },
    {
      id: 'time',
      icon: <TimeIcon />,
      amount: timeSaved,
      title: 'Time Saved',
      text: 'Saved time with Justdone compared to writing the text yourself',
    },
  ]; // TODO! Think about merging with FomoLost Component

  return (
    <div>
      <Typography
        variant={Typographies.HEADLINE_SMALL}
        component="h3"
        className={styles.title}
      >
        Total stats
      </Typography>

      <div className={styles.list}>
        {statList.map((item) => (
          <StatisticsItem item={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default Statistics;
