import React, { FC } from 'react';

import { SUPPORT_EMAIL } from '~/constants';
import analytics from '~/services/analytics';

import { ReactComponent as ChipIcon } from './icons/chip.svg';
import { ReactComponent as CloudIcon } from './icons/cloud.svg';
import { ReactComponent as DeviceIcon } from './icons/device.svg';
import { ReactComponent as EarthIcon } from './icons/earth.svg';
import { ReactComponent as GoogleIcon } from './icons/google.svg';
import { ReactComponent as HeartsIcon } from './icons/hearts.svg';
import { ReactComponent as MagicIcon } from './icons/magic.svg';
import { ReactComponent as MegaphoneIcon } from './icons/megaphone.svg';
import { ReactComponent as RobotIcon } from './icons/robot.svg';
import { ReactComponent as SphereIcon } from './icons/sphere.svg';
import { ReactComponent as TextIcon } from './icons/text.svg';
import { ReactComponent as TooltipIcon } from './icons/tooltip.svg';
import { ReactComponent as Icon1 } from './icons2/icon1.svg';
import { ReactComponent as Icon10 } from './icons2/icon10.svg';
import { ReactComponent as Icon11 } from './icons2/icon11.svg';
import { ReactComponent as Icon12 } from './icons2/icon12.svg';
import { ReactComponent as Icon2 } from './icons2/icon2.svg';
import { ReactComponent as Icon3 } from './icons2/icon3.svg';
import { ReactComponent as Icon4 } from './icons2/icon4.svg';
import { ReactComponent as Icon5 } from './icons2/icon5.svg';
import { ReactComponent as Icon6 } from './icons2/icon6.svg';
import { ReactComponent as Icon7 } from './icons2/icon7.svg';
import { ReactComponent as Icon8 } from './icons2/icon8.svg';
import { ReactComponent as Icon9 } from './icons2/icon9.svg';

export const DETAIL_LIST: {
  Icon: FC<{ className?: string }>;
  text: string;
  onClick?: () => void;
}[] = [
  {
    Icon: (props) => <MagicIcon {...props} />,
    text: 'Powered by GPT-4',
  },
  {
    Icon: (props) => <ChipIcon {...props} />,
    text: 'Chat Personality',
  },
  {
    Icon: (props) => <TextIcon {...props} />,
    text: 'Tone of Voice Selection',
  },
  {
    Icon: (props) => <TooltipIcon {...props} />,
    text: 'Ultimate AI Assistant',
  },
  {
    Icon: (props) => <GoogleIcon {...props} />,
    text: 'Connect to Google Data',
  },
  {
    Icon: (props) => <SphereIcon {...props} />,
    text: 'Best in game Prompt Improver',
  },
  {
    Icon: (props) => <EarthIcon {...props} />,
    text: 'Analyze external sites',
  },
  {
    Icon: (props) => <MegaphoneIcon {...props} />,
    text: 'Analyze files with AI',
  },
  {
    Icon: (props) => <CloudIcon {...props} />,
    text: '100+ Advanced Tools',
  },
  {
    Icon: (props) => <DeviceIcon {...props} />,
    text: 'Long-form Article Generator ',
  },
  {
    Icon: (props) => <RobotIcon {...props} />,
    text: 'Premium Support',
    onClick: (): void => {
      analytics.trackEvent('support - click support email');
      window.open(`mailto:${SUPPORT_EMAIL}`, '_blank');
    },
  },
  {
    Icon: (props) => <HeartsIcon {...props} />,
    text: 'Priority access to new features',
  },
];

export const DETAIL_LIST_2: {
  Icon: FC<{ className?: string }>;
  text: string;
  onClick?: () => void;
}[] = [
  {
    Icon: (props) => <Icon1 {...props} />,
    text: 'Powered by Advanced AI Models',
  },
  {
    Icon: (props) => <Icon2 {...props} />,
    text: 'Innovative AI Text Humanizer',
  },
  {
    Icon: (props) => <Icon3 {...props} />,
    text: 'Analyze files with AI',
  },
  {
    Icon: (props) => <Icon4 {...props} />,
    text: 'Sophisticated Image Generation',
  },
  {
    Icon: (props) => <Icon5 {...props} />,
    text: 'Article Generator for 2000+ words',
  },
  {
    Icon: (props) => <Icon6 {...props} />,
    text: 'Analyze external sites',
  },
  {
    Icon: (props) => <Icon7 {...props} />,
    text: 'Tone of Voice Selection',
  },
  {
    Icon: (props) => <Icon8 {...props} />,
    text: 'Browser Extension',
  },
  {
    Icon: (props) => <Icon9 {...props} />,
    text: 'AI Detector with 99% precision',
  },
  {
    Icon: (props) => <Icon10 {...props} />,
    text: 'Best-in-game Prompt Improver',
  },
  {
    Icon: (props) => <Icon11 {...props} />,
    text: '120+ Writing Tools',
  },
  {
    Icon: (props) => <Icon12 {...props} />,
    text: 'Premium Support',
    onClick: (): void => {
      analytics.trackEvent('support - click support email');
      window.open(`mailto:${SUPPORT_EMAIL}`, '_blank');
    },
  },
];
