import React, { FC, useMemo, useState } from 'react';

import { Typography } from '@mui/material';

import LoadingButton from '~/components/atoms/buttons/LoadingButton';
import PaymentForm, {
  paymentFormModalConfig,
} from '~/components/organisms/PaymentForm/views/PaymentForm';
import { useModalContext } from '~/contexts/modal/ModalContext';
import useErrors from '~/hooks/useErrors';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { Typographies } from '~/theme/typography';
import { ProductType } from '~/types/product';
import { SUBSCRIPTION_STATUS, SubscriptionType } from '~/types/subscription';
import { timeout } from '~/utils/common';

import styles from './styles.module.scss';
import Toggler, { PlanVariants } from './Toggler';
import ProductCard from '../../../components/ProductCard';

const getVisibleProducts = (
  products: ProductType[],
  selectedVariant: PlanVariants,
): ProductType[] => {
  return products.some((p) => p.is_unlimited)
    ? products.filter((p) => {
        if (selectedVariant === PlanVariants.UNLIMITED) {
          return p.is_unlimited;
        }

        return !p.is_unlimited;
      })
    : products;
};

const getHighestPriceProduct = (products: ProductType[]): ProductType => {
  return products.reduce((prev, current) => {
    return current.price > prev.price ? current : prev;
  }, products[0]);
};

type Props = {
  products: ProductType[];
  subscription: SubscriptionType;
};

const Products: FC<Props> = ({ products, subscription }) => {
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(
    null,
  );
  const [selectedPlansVariant, setSelectedPlansVariant] =
    useState<PlanVariants>(PlanVariants.UNLIMITED);

  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);
  const { handleOpenModal } = useModalContext();
  const { reportProfileError } = useErrors();
  const { loadAndSetActualProfileData } = useActions();

  const handleTogglerClick = (variant: PlanVariants): void => {
    setSelectedPlansVariant(variant);
  };

  const handleProductClick = (product: ProductType): void => {
    if (product.id === selectedProduct?.id) {
      return;
    }

    setSelectedProduct(product);
  };

  const handleProductSubmit = (): void => {
    if (selectedProduct) {
      analytics.trackEvent('subscription - change sub', {
        sub_id: selectedProduct.id,
      });

      handleOpenModal({
        onClose: () => {
          analytics.trackEvent('platform checkout - close');
        },
        ...paymentFormModalConfig,
        component: ({ onClose }) => (
          <PaymentForm
            product={selectedProduct}
            onSuccessPayment={async (): Promise<void> => {
              try {
                await timeout(4000);
                await loadAndSetActualProfileData();
              } catch (e) {
                reportProfileError(e);
              } finally {
                onClose();
              }
            }}
          />
        ),
      });
    }
  };

  const visibleProducts = useMemo(() => {
    const productsToShow = getVisibleProducts(products, selectedPlansVariant);
    const highestPriceProduct = getHighestPriceProduct(productsToShow);

    setSelectedProduct(highestPriceProduct);

    return productsToShow;
  }, [selectedPlansVariant, products]);

  const isVisibleToggler = useMemo(() => {
    return products.reduce(
      (acc, el) =>
        acc || (el.is_unlimited && products.some((e) => !e.is_unlimited)),
      false,
    );
  }, [products]);

  return (
    <div>
      <Typography
        variant={Typographies.HEADLINE_SMALL}
        component="h3"
        className={styles.title}
      >
        Choose{' '}
        {subscription.status !== SUBSCRIPTION_STATUS.CANCELLED && 'Upgrade'}{' '}
        Plan
      </Typography>

      {isVisibleToggler && (
        <Toggler
          currentVariant={selectedPlansVariant}
          onClick={handleTogglerClick}
        />
      )}

      {visibleProducts.length > 0 && (
        <div className={styles.product__list}>
          {visibleProducts.map((product) => (
            <div className={styles.product__card} key={product.id}>
              <ProductCard
                isSelectable
                product={product}
                isActive={selectedProduct?.id === product.id}
                onProductClick={handleProductClick}
              />
            </div>
          ))}
        </div>
      )}

      <LoadingButton
        color="gradient"
        variant="contained"
        fullWidth={!isDesktop}
        onClick={handleProductSubmit}
      >
        Upgrade Now
      </LoadingButton>
    </div>
  );
};

export default Products;
