import React, { FC, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as RadioIcon } from '../../assets/radio_button.svg';

const weekOptions = [2, 4, 6];

type Props = {
  onSubmit: (pauseDuration: number) => Promise<void>;
  onRefuse: () => void;
};

const PauseModal: FC<Props> = ({ onSubmit, onRefuse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentOption, setCurrentOption] = useState(weekOptions[0]);

  const { t } = useTranslation('modal');

  const handleSubmit = async (pauseDuration: number): Promise<void> => {
    try {
      setIsLoading(true);
      analytics.trackEvent('holiday pause - pause confirm', {
        option: `${pauseDuration} weeks`,
      });
      await onSubmit(pauseDuration);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefuse = (): void => {
    onRefuse();
  };

  const handleOptionClick = (option: number): void => {
    analytics.trackEvent('holiday pause - pause option click', {
      option: `${option} weeks`,
    });

    setCurrentOption(option);
  };

  useEffect(() => {
    analytics.trackEvent('holiday pause - holiday pause modal view');
  }, []);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <div className={styles.img}>
            <CalendarIcon />
          </div>

          <Typography
            variant={Typographies.TITLE_LARGE}
            component="div"
            className={styles.title}
          >
            {t('holiday_pause_sub.title', 'Winter Break? Holiday Plans?')}
          </Typography>

          <Typography
            variant={Typographies.BODY_LARGE}
            component="div"
            className={styles.text}
          >
            <Trans i18nKey="holiday_pause_sub.text" t={t}>
              Pause your premium plan, and save money for what matters most.
              <br />
              You will not be charged until the pause is over.
            </Trans>
          </Typography>

          <div className={styles.options}>
            {weekOptions.map((option) => (
              <div
                key={option}
                className={clsx(styles.options__item, {
                  [styles.is_active]: currentOption === option,
                })}
                onClick={() => handleOptionClick(option)}
              >
                <RadioIcon className={styles.options__item_radio} />

                <Typography
                  variant={Typographies.TITLE_MEDIUM}
                  component="div"
                  className={styles.options__item_text}
                >
                  {option} {t('general:weeks', 'weeks')}
                </Typography>
              </div>
            ))}
          </div>

          <div className={styles.buttons_container}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(currentOption)}
              className={styles.submit_button}
            >
              {t('holiday_pause_sub.submit_button', {
                option: currentOption,
                defaultValue: 'Pause for {{option}} weeks',
              })}
            </Button>

            <Button variant="text" color="primary" onClick={handleRefuse}>
              {t('general:cancel', 'Cancel')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PauseModal;
