import React, { FC } from 'react';

import { Icon } from '@iconify/react';

import Redemption from '~/modules/settings/views/Subscription/SubscriptionInfo/components/Redemption';
import Table from '~/modules/settings/views/Subscription/SubscriptionInfo/components/Table';
import Words from '~/modules/settings/views/Subscription/SubscriptionInfo/components/Words';
import Workspace from '~/modules/settings/views/Subscription/SubscriptionInfo/components/Workspace';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { SUBSCRIPTION_STATUS, SubscriptionType } from '~/types/subscription';

import { getWordsLimit } from './helpers';
import FrozenBlock from '../FrozenBlock';

type Props = {
  subscription: SubscriptionType;
  isFrozenSubscriptionBlock?: boolean;
};

const SubscriptionInfo: FC<Props> = ({
  subscription,
  isFrozenSubscriptionBlock,
}) => {
  const profile = useAppSelector((state) => state.profile);

  const {
    is_unlimited,
    is_trial,
    subscription_price,
    billing_period,
    status,
    words_amount_total,
    words_amount_left,
    next_charge_at,
  } = subscription;

  const wordBalance = getWordsLimit(
    status,
    is_unlimited,
    words_amount_left,
    words_amount_total,
  );

  return (
    <div>
      {wordBalance && status !== SUBSCRIPTION_STATUS.CANCELLED && (
        <div style={{ marginBottom: '40px' }}>
          <Words
            wordBalance={wordBalance}
            status={status}
            isUnlimited={is_unlimited}
            wordsLeft={words_amount_left}
            wordsTotal={words_amount_total}
            nextChargeAt={next_charge_at}
          />
        </div>
      )}

      <div style={{ marginBottom: '40px' }}>
        <Workspace createdAt={profile.created_at} subscription={subscription} />
      </div>

      {[
        SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING,
        SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_FAILED,
        SUBSCRIPTION_STATUS.REDEMPTION,
      ].includes(status) && (
        <div style={{ marginBottom: '40px' }}>
          <Redemption status={status} />
        </div>
      )}

      {isFrozenSubscriptionBlock ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FrozenBlock
            text="This section will be available after your payment is confirmed and
              verified"
          />
          <Icon
            style={{ marginLeft: '20px', fontSize: '30px' }}
            icon="svg-spinners:tadpole"
          />
        </div>
      ) : (
        <Table
          status={status}
          isTrial={is_trial}
          billingPeriod={billing_period}
          subPrice={subscription_price}
        />
      )}
    </div>
  );
};

export default SubscriptionInfo;
