import { BUTTON_SUGGESTION } from '~/components/layouts/MainLayout/components/ButtonSuggestion';
import { BLOCKED_SUB_CANCELLING_TIME } from '~/modules/settings/views/Subscription';
import DateService from '~/services/Date';
import { REGULARITY } from '~/types/product';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

export const getButtonSuggestion = (data: {
  subStatus: SUBSCRIPTION_STATUS;
  regularity: REGULARITY;
  isExtensionInstalled: boolean;
  isStripeUser: boolean;
  signUpAt: string;
}): BUTTON_SUGGESTION | null => {
  const {
    subStatus,
    regularity,
    isExtensionInstalled,
    isStripeUser,
    signUpAt,
  } = data;

  if (
    !isStripeUser &&
    [
      SUBSCRIPTION_STATUS.CANCEL_PENDING,
      SUBSCRIPTION_STATUS.CANCELLED,
    ].includes(subStatus)
  ) {
    return BUTTON_SUGGESTION.RESTORE;
  }

  if (!isStripeUser && subStatus === SUBSCRIPTION_STATUS.REDEMPTION) {
    return BUTTON_SUGGESTION.REDEMPTION;
  }

  if (
    !isStripeUser &&
    subStatus === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING
  ) {
    return BUTTON_SUGGESTION.REDEMPTION_PENDING;
  }

  if (
    !isStripeUser &&
    subStatus === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_FAILED
  ) {
    return BUTTON_SUGGESTION.REDEMPTION_FAILED;
  }

  if (!isStripeUser && subStatus === SUBSCRIPTION_STATUS.PAUSED) {
    return BUTTON_SUGGESTION.UNPAUSE;
  }

  if (
    !isStripeUser &&
    subStatus === SUBSCRIPTION_STATUS.ACTIVE &&
    regularity !== REGULARITY.YEAR &&
    signUpAt &&
    Math.abs(DateService.differenceInMinutes(new Date(), new Date(signUpAt))) >
      BLOCKED_SUB_CANCELLING_TIME
  ) {
    return BUTTON_SUGGESTION.PAUSE;
  }

  if (!isStripeUser && regularity !== REGULARITY.YEAR) {
    return BUTTON_SUGGESTION.UPGRADE;
  }

  if (!isExtensionInstalled) {
    return BUTTON_SUGGESTION.EXTENSION;
  }

  return null;
};
