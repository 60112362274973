import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';
import { ReactComponent as RocketIcon } from '../../assets/rocket.svg';

type Props = {
  onClose: () => void;
};

const UnpauseSuccessModal: FC<Props> = ({ onClose }) => {
  const profile = useAppSelector((state) => state.profile);
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const { t } = useTranslation('modal');

  useEffect(() => {
    analytics.trackEvent('holiday pause - unpause success modal view');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.img}>
        <RocketIcon />
      </div>

      <Typography
        variant={Typographies.TITLE_LARGE}
        component="div"
        className={styles.title}
      >
        {t('unpause_sub_success.title', 'You are all set!')}
      </Typography>

      <Typography
        variant={Typographies.BODY_LARGE}
        component="div"
        className={styles.text}
      >
        {t('unpause_sub_success.text', {
          product_name: profile.user_product.name,
          defaultValue:
            'Your {{product_name}} is now active, and you can enjoy all the premium features without any interruptions.',
        })}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        fullWidth={!isDesktop}
        onClick={onClose}
      >
        {t('general:got_it', 'Got it')}
      </Button>
    </div>
  );
};

export default UnpauseSuccessModal;
