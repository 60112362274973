import React, { FC, useEffect, useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import { useModalContext } from '~/contexts/modal/ModalContext';
import useErrors from '~/hooks/useErrors';
import { CancellationPath } from '~/modules/cancellation/constants';
import PauseModal from '~/modules/cancellation/features/pause/components/PauseModal';
import usePauseSubscription from '~/modules/cancellation/features/pause/hooks/usePauseSubscription';
import { SCREEN } from '~/modules/cancellation/views/variants/v3';
import ChooseSubscriptionModal, {
  ReasonType,
} from '~/modules/settings/components/modals/ChooseSubscriptionModal';
import FomoModal from '~/modules/settings/components/modals/FomoModal';
import { useRestoreSubscription } from '~/modules/settings/hooks/useRestoreSubscription';
import commonStyles from '~/modules/settings/views/commonStyles.module.scss';
import Paused from '~/modules/settings/views/Subscription/Paused';
import ProductDetails from '~/modules/settings/views/Subscription/PlanDetails';
import { DETAIL_LIST } from '~/modules/settings/views/Subscription/PlanDetails/constants';
import analytics from '~/services/analytics';
import DateService from '~/services/Date';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';
import { ProductType, REGULARITY } from '~/types/product';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

import FrozenBlock from './FrozenBlock';
import Products from './Products';
import Statistics from './Statistics';
import SubManager from './SubManager';
import SubscriptionInfo from './SubscriptionInfo';

export const BLOCKED_SUB_CANCELLING_TIME = 50; // 50 mins

export enum SubProcessingStatus {
  SETTLED = 'settled',
  PENDING = 'pending',
  PAUSED = 'paused',
}

let interval: NodeJS.Timeout | null = null;

const Subscription: FC = () => {
  const [products, setProducts] = useState<ProductType[] | null>(null);
  const [subProcessingStatus, setSubProcessingStatus] =
    useState<SubProcessingStatus>(SubProcessingStatus.SETTLED);

  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const { reportUserErrors } = useErrors();
  const { loadAndDispatchActualSubscriptionData } = useActions();
  const { handleOpenModal } = useModalContext();
  const { handlePauseSub } = usePauseSubscription();
  const { restoreSubscription } = useRestoreSubscription();

  const subscription = profile.user_subscription;

  const isFrozenSubscriptionBlock: boolean = useMemo(() => {
    if (
      SUBSCRIPTION_STATUS.ACTIVE !== profile.user_subscription.status ||
      profile.user_subscription.payment_type === 'paypal-vault'
    ) {
      return false;
    }

    if (profile.extra_fields.sign_up_at) {
      const isBlockedTime =
        Math.abs(
          DateService.differenceInMinutes(
            new Date(),
            new Date(profile.extra_fields.sign_up_at),
          ),
        ) < BLOCKED_SUB_CANCELLING_TIME;

      const isActiveSub =
        profile.user_subscription.status === SUBSCRIPTION_STATUS.ACTIVE;

      return isBlockedTime && isActiveSub;
    }

    return false;
  }, [profile]);

  const handleCancelSubClick = (): void => {
    analytics.trackEvent('subscription - want cancel sub', {
      sub: subscription.subscription_name,
    });

    if (subscription.payment_type === 'paypal-vault') {
      navigate(
        { pathname: CancellationPath, search: `?screen=${SCREEN.CANCEL_FORM}` },
        { replace: true },
      );

      return;
    }

    handleOpenModal({
      onClose: () => {
        analytics.trackEvent('subscription - close cancel sub modal', {
          sub: subscription.subscription_name,
        });
      },
      component: ({ onClose }) => (
        <FomoModal
          subscriptionWordsLeft={subscription.words_amount_left}
          isSubscriptionUnlimited={subscription.is_unlimited}
          onKeepPlan={onClose}
          onCancelPlan={(): void => {
            if (
              subscription.status !== SUBSCRIPTION_STATUS.PAUSED &&
              profile.user_product.regularity !== REGULARITY.YEAR
            ) {
              handleOpenModal({
                closeButtonColor: '#76777A',
                disableBackdropClick: true,
                onClose: () => {
                  analytics.trackEvent('holiday pause - pause decline click');
                  navigate(CancellationPath);
                },
                component: ({ onClose }) => (
                  <PauseModal
                    onSubmit={async (pauseDuration): Promise<void> =>
                      handlePauseSub({ pauseDuration })
                    }
                    onRefuse={onClose}
                  />
                ),
              });
            } else {
              navigate(CancellationPath);
              setTimeout(() => {
                onClose();
              }, 0);
            }
          }}
          subscriptionName={subscription.subscription_name}
        />
      ),
    });
  };

  const handleRestoreSubClick = async (): Promise<void> => {
    analytics.trackEvent('subscription - restore sub', {
      sub: subscription.subscription_name,
    });

    if (subscription.status === SUBSCRIPTION_STATUS.CANCEL_PENDING) {
      try {
        await restoreSubscription();
      } catch (e) {
        reportUserErrors({
          error: e,
          method: 'restoreSubscription',
          userMessage:
            'Unable to restore subscription. Please contact support or',
        });
      }
      return;
    }

    handleOpenModal({
      onClose: () => {
        analytics.trackEvent('subscription - close not active sub screen', {
          sub: subscription.subscription_name,
        });
      },
      maxWidth: '460px',
      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.NO_ACTIVE_SUBSCRIPTION}
        />
      ),
    });

    analytics.trackEvent('subscription - not active sub screen', {
      sub: subscription.subscription_name,
    });
  };

  useEffect(() => {
    analytics.trackEvent('navigation - open subscription settings');
  }, []);

  useEffect(() => {
    interval = setInterval(
      () => loadAndDispatchActualSubscriptionData(),
      10000,
    );

    return (): void => {
      interval && clearInterval(interval);
      interval = null;
    };
  }, [subProcessingStatus]);

  useEffect(() => {
    loadAndDispatchActualSubscriptionData();
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const products = await Api.getProductList();
        setProducts(products);
      } catch (e: any) {
        reportUserErrors({
          error: e,
          method: 'getProductList',
          userMessage: 'Failed to load product list. Please try again or',
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (!subscription) {
      return;
    }

    if (
      [
        SUBSCRIPTION_STATUS.PAUSE_PENDING,
        SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING,
      ].includes(subscription.status)
    ) {
      setSubProcessingStatus(SubProcessingStatus.PENDING);
      return;
    }

    interval && clearInterval(interval);
    interval = null;
    setSubProcessingStatus(
      subscription.status === SUBSCRIPTION_STATUS.PAUSED
        ? SubProcessingStatus.PAUSED
        : SubProcessingStatus.SETTLED,
    );
  }, [subscription]);

  const filteredProducts = useMemo(() => {
    if (!products || products.length === 0 || !subscription) {
      return;
    }

    return products.filter(
      (product) =>
        product.id !== subscription.product_id &&
        product.regularity !== REGULARITY.ONETIME &&
        !product.has_trial_period,
    );
  }, [products, subscription]);

  return (
    <div className={commonStyles.container}>
      <Typography
        variant={Typographies.HEADLINE_LARGE}
        component="h2"
        className={commonStyles.title}
      >
        Subscription
      </Typography>

      {subscription ? (
        <>
          {subscription.status === SUBSCRIPTION_STATUS.PAUSED ? (
            <Paused
              subscription={subscription}
              subProcessingStatus={subProcessingStatus}
              products={
                !profile.is_stripe_user &&
                filteredProducts &&
                filteredProducts.length > 0 &&
                subProcessingStatus !== SubProcessingStatus.PENDING
                  ? filteredProducts
                  : null
              }
              onCancelSubClick={handleCancelSubClick}
            />
          ) : (
            <>
              {subscription.response_count_total > 0 && (
                <Statistics
                  wordsGenerated={subscription.words_generated_total}
                  tasksCompleted={subscription.response_count_total}
                  timeSaved={subscription.saved_time_minutes}
                />
              )}

              {!profile.is_stripe_user &&
                filteredProducts &&
                filteredProducts.length > 0 &&
                subProcessingStatus !== SubProcessingStatus.PENDING && (
                  <Products
                    products={filteredProducts}
                    subscription={subscription}
                  />
                )}
              <ProductDetails items={DETAIL_LIST} />

              {subProcessingStatus !== SubProcessingStatus.PENDING ? (
                <SubscriptionInfo
                  subscription={subscription}
                  isFrozenSubscriptionBlock={isFrozenSubscriptionBlock}
                />
              ) : (
                <FrozenBlock
                  title="Subscription Management"
                  isWithLoader
                  text={
                    <>
                      This section will be available shortly after your
                      subscription status is updated.
                      <br />
                      Usually within a few minutes
                    </>
                  }
                />
              )}

              {!isFrozenSubscriptionBlock && (
                <SubManager
                  subProcessingStatus={subProcessingStatus}
                  subscription={subscription}
                  onCancelSubClick={handleCancelSubClick}
                  onRestoreSubClick={handleRestoreSubClick}
                />
              )}
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Subscription;
