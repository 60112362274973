import React, { FC, useRef } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import useIntersectionObserver from '~/hooks/useIntersectionObserver';
import { getWordsLeft } from '~/modules/settings/views/Subscription/SubscriptionInfo/helpers';
import DateService from '~/services/Date';
import { Typographies } from '~/theme/typography';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

import styles from './styles.module.scss';

type Props = {
  wordBalance: string | JSX.Element | null;
  status: SUBSCRIPTION_STATUS;
  isUnlimited: boolean;
  wordsLeft: number;
  wordsTotal: number;
  nextChargeAt: string | null;
};

const Words: FC<Props> = ({
  wordBalance,
  status,
  isUnlimited,
  wordsLeft,
  wordsTotal,
  nextChargeAt,
}) => {
  const progressRef = useRef<HTMLDivElement | null>(null);
  const isIntersected = useIntersectionObserver(progressRef, {}, true);

  return (
    <div className={styles.words}>
      <Typography
        variant={Typographies.HEADLINE_SMALL}
        component="h3"
        className={styles.words__title}
      >
        Word balance for the current billing period
      </Typography>

      <Typography
        variant={Typographies.TITLE_LARGE}
        component="p"
        sx={{
          fontWeight: '500',
        }}
      >
        {wordBalance}
      </Typography>

      <div
        ref={progressRef}
        className={clsx(styles.words__progress, {
          [styles.is_unlimited]: isUnlimited,
        })}
      >
        <i
          className={styles.words__progress__fill}
          style={{
            width: isUnlimited
              ? '100%'
              : isIntersected
                ? `${((wordsTotal - wordsLeft) / wordsTotal) * 100}%`
                : '0',
          }}
        />
      </div>

      <Typography
        variant={Typographies.BODY_LARGE}
        component="p"
        className={styles.words__sub_text}
      >
        {getWordsLeft(status, isUnlimited, wordsLeft)}
      </Typography>

      {nextChargeAt && (
        <Typography variant={Typographies.TITLE_MEDIUM} component="p">
          Renews on {DateService.format(new Date(nextChargeAt), 'MMM d yyyy')}
        </Typography>
      )}
    </div>
  );
};

export default Words;
