import { MutableRefObject, useEffect, useRef, useState } from 'react';

export default (
  initialElement: MutableRefObject<HTMLDivElement | null>,
  options: IntersectionObserverInit = {},
  triggerOnce = false,
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasIntersected, setHasIntersected] = useState(false); // New flag
  const observer = useRef<null | IntersectionObserver>(null);

  const cleanOb = (): void => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  useEffect(() => {
    if (!initialElement.current || hasIntersected) return;

    cleanOb();

    const ob = (observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;

        setIsIntersecting(isElementIntersecting);

        if (!isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting);
          cleanOb();

          if (triggerOnce) {
            setHasIntersected(true);
          }
        }
      },
      { ...options },
    ));

    ob.observe(initialElement.current);

    return (): void => {
      cleanOb();
    };
  }, [initialElement.current, options]);

  return isIntersecting;
};
