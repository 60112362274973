import React from 'react';

import DateService from '~/services/Date';
import { REGULARITY } from '~/types/product';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

import { ReactComponent as InfinityIcon } from './assets/infinity.svg';

const LY_BILLING_WORD_ENDING = [
  REGULARITY.YEAR,
  REGULARITY.MONTH,
  REGULARITY.WEEK,
];

export const getWillBeChargedText = (
  status: SUBSCRIPTION_STATUS,
  subscription_price: number,
): string => {
  switch (status) {
    case SUBSCRIPTION_STATUS.CANCELLED:
      return 'No, sub is canceled';
    case SUBSCRIPTION_STATUS.PAUSED:
      return 'No, sub is paused';
    case SUBSCRIPTION_STATUS.PAUSE_PENDING:
      return 'No, sub is paused';
    case SUBSCRIPTION_STATUS.CANCEL_PENDING:
      return 'No, sub is canceled';
    default:
      return `$${subscription_price}`;
  }
};

export const getStatusText = (
  status: SUBSCRIPTION_STATUS,
  expired_at: string | null,
  product_name: string,
  pause_to: string | null,
): string => {
  switch (status) {
    case SUBSCRIPTION_STATUS.ACTIVE:
      return `You are currently on the ${product_name}`;
    case SUBSCRIPTION_STATUS.PAUSED:
      return `Your subscription is paused ${
        pause_to
          ? 'till ' + DateService.format(new Date(pause_to), 'MMM d yyyy')
          : ' '
      }`;
    case SUBSCRIPTION_STATUS.PAUSE_PENDING:
      return `Your subscription is paused`;
    case SUBSCRIPTION_STATUS.CANCELLED:
      return 'Your subscription is canceled';
    case SUBSCRIPTION_STATUS.CANCEL_PENDING:
      return `Your subscription is canceled, but you can use platform till ${
        expired_at
          ? DateService.format(new Date(expired_at), 'MMM d yyyy')
          : ' - '
      } `;
    default:
      return '';
  }
};

export const getBillingPeriod = (
  status: SUBSCRIPTION_STATUS,
  billing_period: REGULARITY,
): string => {
  return billing_period
    ? LY_BILLING_WORD_ENDING.includes(billing_period)
      ? `${billing_period.charAt(0).toUpperCase() + billing_period.slice(1)}ly`
      : billing_period
    : ' - ';
};
export const getTrialStatus = (
  status: SUBSCRIPTION_STATUS,
  isTrial: boolean,
): string => {
  if (SUBSCRIPTION_STATUS.PAUSED === status) {
    return 'Not Active';
  }

  if (
    [
      SUBSCRIPTION_STATUS.ACTIVE,
      SUBSCRIPTION_STATUS.PAUSE_PENDING,
      SUBSCRIPTION_STATUS.CANCEL_PENDING,
      SUBSCRIPTION_STATUS.REDEMPTION,
    ].includes(status) &&
    isTrial
  ) {
    return 'Active';
  }

  return 'Inactive';
};

export const getWordsLimit = (
  status,
  is_unlimited: boolean,
  words_amount_left: number,
  words_amount_total: number,
): JSX.Element | string | null => {
  if (is_unlimited) {
    return (
      <div style={{ width: '40px' }}>
        <InfinityIcon />
      </div>
    );
  }

  return `${(
    words_amount_total - words_amount_left
  ).toLocaleString()} / ${words_amount_total.toLocaleString()}`;
};

export const getWordsLeft = (
  status: SUBSCRIPTION_STATUS,
  is_unlimited: boolean,
  words_amount_left: number,
): string => {
  if (status !== SUBSCRIPTION_STATUS.ACTIVE) {
    return '';
  }

  if (is_unlimited) {
    return 'Unlimited Words';
  }

  if (words_amount_left === 0) {
    return 'No words remain';
  }

  return '';
};
