// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KV8giaz5oWf70Z7H21PW{width:172px;margin:0 auto 24px}.VCsTROiM_L0hzjgAkw8i{font-weight:500 !important;margin-bottom:12px !important}.q95ZUkHSkpIuOwaPF7DG{font-size:16px !important;line-height:24px !important;margin-bottom:32px !important}`, "",{"version":3,"sources":["webpack://./src/modules/cancellation/features/pause/components/UnpauseSuccessModal/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,WAAA,CACA,kBAAA,CAGF,sBACE,0BAAA,CACA,6BAAA,CAGF,sBACE,yBAAA,CACA,2BAAA,CACA,6BAAA","sourcesContent":["@import \"src/styles/mixins\";\n@import \"src/styles/colors\";\n\n.img {\n  width: 172px;\n  margin: 0 auto 24px;\n}\n\n.title {\n  font-weight: 500 !important;\n  margin-bottom: 12px !important;\n}\n\n.text {\n  font-size: 16px !important;\n  line-height: 24px !important;\n  margin-bottom: 32px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `KV8giaz5oWf70Z7H21PW`,
	"title": `VCsTROiM_L0hzjgAkw8i`,
	"text": `q95ZUkHSkpIuOwaPF7DG`
};
export default ___CSS_LOADER_EXPORT___;
