import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AttentionImg from '~/assets/images/attention.webp';
import AttentionPreviewImg from '~/assets/images/attention_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import Loader from '~/components/atoms/Loader';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  text: JSX.Element;
  onSupport: () => void;
  onRetry: () => Promise<void>;
};

const ErrorModal: FC<Props> = ({ text, onSupport, onRetry }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('modal');

  const handleRetry = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await onRetry();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <div className={styles.image}>
            <Image src={AttentionImg} preview={AttentionPreviewImg} />
          </div>

          <Typography
            variant={Typographies.TITLE_LARGE}
            component="div"
            className={styles.title}
          >
            {t('pause_sub_error.title', 'Oops! Something went wrong')}.
          </Typography>

          <Typography
            variant={Typographies.BODY_LARGE}
            component="div"
            className={styles.text}
          >
            {text}
          </Typography>

          <div className={styles.buttons_container}>
            <Button variant="contained" color="primary" onClick={onSupport}>
              {t('general:contact_support', 'Contact Support')}
            </Button>

            <Button variant="text" color="primary" onClick={handleRetry}>
              {t('general:try_again', 'Try again')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ErrorModal;
