import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import LoadingButton from '~/components/atoms/buttons/LoadingButton';
import UnpauseSuggestionButton from '~/modules/cancellation/features/pause/components/UnpauseSuggestionButton';
import usePauseSubscription from '~/modules/cancellation/features/pause/hooks/usePauseSubscription';
import { SubProcessingStatus } from '~/modules/settings/views/Subscription';
import ProductDetails from '~/modules/settings/views/Subscription/PlanDetails';
import { DETAIL_LIST } from '~/modules/settings/views/Subscription/PlanDetails/constants';
import Products from '~/modules/settings/views/Subscription/Products';
import Statistics from '~/modules/settings/views/Subscription/Statistics';
import Words from '~/modules/settings/views/Subscription/SubscriptionInfo/components/Words';
import { getWordsLimit } from '~/modules/settings/views/Subscription/SubscriptionInfo/helpers';
import analytics from '~/services/analytics';
import DateService from '~/services/Date';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';
import { ProductType } from '~/types/product';
import { SubscriptionType } from '~/types/subscription';

import styles from './styles.module.scss';

type Props = {
  subscription: SubscriptionType;
  subProcessingStatus: SubProcessingStatus;
  products: ProductType[] | null;
  onCancelSubClick: () => void;
};

const Paused: FC<Props> = ({
  subscription,
  subProcessingStatus,
  products,
  onCancelSubClick,
}) => {
  const {
    subscription_name,
    is_unlimited,
    status,
    words_amount_total,
    words_amount_left,
    next_charge_at,
    pause_to,
  } = subscription;

  const [isLoading, setIsLoading] = useState(false);

  const { handleUnpauseSub } = usePauseSubscription();
  const profile = useAppSelector((state) => state.profile);
  const { t } = useTranslation('subscription');

  const wordBalance = getWordsLimit(
    status,
    is_unlimited,
    words_amount_left,
    words_amount_total,
  );

  const handleUnpauseClick = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await handleUnpauseSub();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelClick = (): void => {
    analytics.trackEvent('holiday pause - cancel sub from pause click');
    onCancelSubClick();
  };

  return (
    <div className={styles.container}>
      <div className={styles.pause_header}>
        <Typography
          variant={Typographies.HEADLINE_SMALL}
          component="div"
          className={styles.pause_header__title}
        >
          {t('paused.header_title', 'Your plan is paused')}
        </Typography>

        {profile.user_subscription.pause_to && (
          <Typography
            variant={Typographies.BODY_MEDIUM}
            component="div"
            className={styles.pause_header__text}
          >
            <Trans
              i18nKey="paused.header_text"
              t={t}
              values={{
                date: DateService.differenceInDays(
                  new Date(profile.user_subscription.pause_to),
                  Date.now(),
                ),
              }}
            >
              You can unpause it or wait {'{{date}}'} days to continue using
              premium functionality of Justdone
            </Trans>
          </Typography>
        )}

        <UnpauseSuggestionButton
          onClick={handleUnpauseClick}
          disabled={isLoading}
        />
      </div>

      {subscription.response_count_total > 0 && (
        <Statistics
          wordsGenerated={subscription.words_generated_total}
          tasksCompleted={subscription.response_count_total}
          timeSaved={subscription.saved_time_minutes}
        />
      )}

      {products && <Products products={products} subscription={subscription} />}

      <ProductDetails items={DETAIL_LIST} />

      <div className={styles.pause_footer}>
        <Words
          wordBalance={wordBalance}
          status={status}
          isUnlimited={is_unlimited}
          wordsLeft={words_amount_left}
          wordsTotal={words_amount_total}
          nextChargeAt={next_charge_at}
        />

        <Typography
          variant={Typographies.TITLE_MEDIUM}
          component="div"
          className={styles.pause_footer__desc}
        >
          {t('paused.footer_text', {
            date: pause_to
              ? DateService.differenceInDays(new Date(pause_to), Date.now())
              : '0',
            sub_name: subscription_name,
            defaultValue: 'Your {{sub_name}} is paused. {{date}} days left',
          })}
        </Typography>

        <div className={styles.pause_footer__buttons}>
          <UnpauseSuggestionButton
            onClick={handleUnpauseClick}
            disabled={isLoading}
          />

          <LoadingButton
            disabled={
              subProcessingStatus === SubProcessingStatus.PENDING || isLoading
            }
            variant="outlined"
            color="secondary"
            onClick={handleCancelClick}
            loading={subProcessingStatus === SubProcessingStatus.PENDING}
          >
            {t('general:cancel_plan', 'Cancel Plan')}
          </LoadingButton>
        </div>

        <Typography
          variant={Typographies.TITLE_MEDIUM}
          component="p"
          className={styles.pause_footer__workspace}
        >
          {t('paused.workspace', {
            date: DateService.format(
              new Date(profile.created_at),
              'MMM d yyyy',
            ),
            defaultValue: 'Your workspace was created on {{date}}',
          })}
        </Typography>
      </div>
    </div>
  );
};

export default Paused;
