import React, { FC } from 'react';

import Button from '~/components/atoms/buttons/Button';

import styles from './styles.module.scss';
import ChristmasTreeImg from '../../assets/christmas_tree.webp';

type Props = {
  onClick: () => void;
};

const HolidaySuggestionButton: FC<Props> = ({ onClick }) => {
  return (
    <Button
      fullWidth
      color="secondary"
      variant="contained"
      onClick={onClick}
      className={styles.button}
    >
      <img
        className={styles.button__img}
        src={ChristmasTreeImg}
        alt="christmas"
      />
      <span className={styles.button__text}>Holiday Break!</span>
    </Button>
  );
};

export default HolidaySuggestionButton;
