import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Api from '~/Api';
import SupportModal from '~/components/molecules/SupportModal';
import { useModalContext } from '~/contexts/modal/ModalContext';
import ErrorModal from '~/modules/cancellation/features/pause/components/ErrorModal';
import PauseSuccessModal from '~/modules/cancellation/features/pause/components/PauseSuccessModal';
import UnpauseSuccessModal from '~/modules/cancellation/features/pause/components/UnpauseSuccessModal';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { timeout } from '~/utils/common';

const usePauseSubscription = (): {
  handlePauseSub: (data: { pauseDuration: number }) => Promise<void>;
  handleUnpauseSub: () => Promise<void>;
} => {
  const { t } = useTranslation('modal');

  const { loadAndSetActualProfileData } = useActions();
  const { handleOpenModal } = useModalContext();
  const profile = useAppSelector((state) => state.profile);

  const handleOpenSupportModal = (): void => {
    analytics.trackEvent('navigation - open help section');

    handleOpenModal({
      onClose: () => analytics.trackEvent('navigation - close support modal'),
      component: ({ onClose }) => <SupportModal onClose={onClose} />,
    });
  };

  const handleUnpauseSub = async (): Promise<void> => {
    try {
      await Api.unpauseSubscription();
      await timeout(4000);
      await loadAndSetActualProfileData();

      handleOpenModal({
        closeButtonColor: '#76777A',
        disableBackdropClick: true,
        component: ({ onClose }) => <UnpauseSuccessModal onClose={onClose} />,
      });
    } catch (e: any) {
      handleOpenModal({
        closeButtonColor: '#76777A',
        disableBackdropClick: true,
        onShow: () => {
          analytics.trackEvent('holiday pause - unpause failed modal view');
        },
        component: () => (
          <ErrorModal
            text={
              <Trans i18nKey="modal:pause_sub_error.unpause_text" t={t}>
                It looks like we couldn't unpause your plan.
                <br />
                Reach out to us to resolve the problem.
              </Trans>
            }
            onSupport={() => {
              analytics.trackEvent(
                'holiday pause - unpause failed contact support click',
              );
              handleOpenSupportModal();
            }}
            onRetry={async (): Promise<void> => {
              analytics.trackEvent(
                'holiday pause - unpause failed retry unpause click',
              );
              await handleUnpauseSub();
            }}
          />
        ),
      });
    }
  };

  const handlePauseSub = async (data: {
    pauseDuration: number;
  }): Promise<void> => {
    try {
      await Api.pauseSubscription({
        pause_amount: data.pauseDuration,
        pause_unit: 'week',
        reason: 'holiday',
      });

      await timeout(4000);
      await loadAndSetActualProfileData();

      handleOpenModal({
        closeButtonColor: '#76777A',
        disableBackdropClick: true,
        component: ({ onClose }) => (
          <PauseSuccessModal
            pauseDuration={data.pauseDuration}
            onClose={onClose}
          />
        ),
      });
    } catch (e: any) {
      handleOpenModal({
        closeButtonColor: '#76777A',
        disableBackdropClick: true,
        onShow: () => {
          analytics.trackEvent('holiday pause - pause failed modal view');
        },
        component: () => (
          <ErrorModal
            text={
              <Trans
                i18nKey="modal:pause_sub_error.pause_text"
                t={t}
                values={{ product_name: profile.user_product.name }}
              >
                It looks like we couldn't pause your plan at this time. You're
                still on the {'{{-product_name}}'} and can continue enjoying all
                the features.
                <br />
                If you need any assistance, feel free to reach out!
              </Trans>
            }
            onSupport={() => {
              analytics.trackEvent(
                'holiday pause - pause failed contact support click',
              );
              handleOpenSupportModal();
            }}
            onRetry={async (): Promise<void> => {
              analytics.trackEvent(
                'holiday pause - pause failed retry pause click',
              );
              await handlePauseSub(data);
            }}
          />
        ),
      });
    }
  };

  return { handlePauseSub, handleUnpauseSub };
};

export default usePauseSubscription;
