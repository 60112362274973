import React, { FC, useState } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import PauseImg from '~/assets/images/pause.webp';
import PausePreviewImg from '~/assets/images/pause_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import Loader from '~/components/atoms/Loader';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';
import { REGULARITY } from '~/types/product';

import styles from './styles.module.scss';

const getBenefits = (productRegularity: REGULARITY): JSX.Element[] => {
  switch (productRegularity) {
    case REGULARITY.YEAR:
      return [
        <Trans key="y0" i18nKey="modal:unpause_sub.benefits_yearly[0]">
          <span>Unlimited Words</span> in AI Detector, Humanizer, and Plagiarism
          Check
        </Trans>,
        <Trans key="y1" i18nKey="modal:unpause_sub.benefits_yearly[1]">
          <span>Unlimited Words</span> in AI Chat
        </Trans>,
        <Trans key="y2" i18nKey="modal:unpause_sub.benefits_yearly[2]">
          <span>Full access</span> to Chrome Extension
        </Trans>,
        <Trans key="y3" i18nKey="modal:unpause_sub.benefits_yearly[3]">
          <span>Unlimited Words</span> in other tools
        </Trans>,
        <Trans key="y4" i18nKey="modal:unpause_sub.benefits_yearly[4]">
          <span>Unlimited</span> Prompt Improvements
        </Trans>,
        <Trans key="y5" i18nKey="modal:unpause_sub.benefits_yearly[5]">
          <span>Unlimited</span> Image Generation
        </Trans>,
      ];

    default:
      return [
        <Trans key="d0" i18nKey="modal:unpause_sub.benefits_default[0]">
          <span>80,000 words</span> in AI Detector, Humanizer, and Plagiarism
          Check
        </Trans>,
        <Trans key="d1" i18nKey="modal:unpause_sub.benefits_default[1]">
          <span>Unlimited Words</span> in AI Chat
        </Trans>,
        <Trans key="d2" i18nKey="modal:unpause_sub.benefits_default[2]">
          <span>Full access</span> to Chrome Extension
        </Trans>,
        <Trans key="d3" i18nKey="modal:unpause_sub.benefits_default[3]">
          <span>Unlimited Words</span> in other tools
        </Trans>,
        <Trans key="d4" i18nKey="modal:unpause_sub.benefits_default[4]">
          <span>5 Prompt Improvements</span> per Day
        </Trans>,
        <Trans key="d5" i18nKey="modal:unpause_sub.benefits_default[5]">
          <span>10 Image Generations</span> per Day
        </Trans>,
      ];
  }
};

type Props = {
  onSubmit: () => Promise<void>;
  onRefuse: () => void;
};

const UnpauseModal: FC<Props> = ({ onSubmit, onRefuse }) => {
  const [isLoading, setIsLoading] = useState(false);

  const profile = useAppSelector((state) => state.profile);
  const { t } = useTranslation('modal');

  const benefits = getBenefits(profile.user_product.regularity);

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true);
      analytics.trackEvent('holiday pause - need to unpause click');

      await onSubmit();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <div className={styles.image}>
            <Image src={PauseImg} preview={PausePreviewImg} />
          </div>

          <Typography
            variant={Typographies.TITLE_LARGE}
            component="div"
            className={styles.title}
          >
            {t('unpause_sub.title', 'Your plan is paused')}
          </Typography>

          <Typography
            variant={Typographies.BODY_LARGE}
            component="div"
            className={styles.text}
          >
            {t(
              'unpause_sub.text',
              'Unpause to full access to Justdone features',
            )}
          </Typography>

          <div className={styles.benefits}>
            {benefits.map((option, index) => (
              <div key={index} className={styles.benefits__item}>
                <Icon
                  icon="ic:round-check"
                  className={styles.benefits__item_icon}
                />

                <Typography
                  variant={Typographies.TITLE_MEDIUM}
                  component="div"
                  className={styles.benefits__item_text}
                >
                  {option}
                </Typography>
              </div>
            ))}
          </div>

          <div className={styles.buttons_container}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={styles.submit_button}
            >
              {t('general:unpause_plan', 'Unpause Plan')}
            </Button>

            <Button variant="text" color="primary" onClick={onRefuse}>
              {t('general:dismiss', 'Dismiss')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default UnpauseModal;
