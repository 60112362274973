import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { getStatusText } from '~/modules/settings/views/Subscription/SubscriptionInfo/helpers';
import DateService from '~/services/Date';
import { Typographies } from '~/theme/typography';
import { SubscriptionType } from '~/types/subscription';

type Props = {
  createdAt: string;
  subscription: SubscriptionType;
};

const Workspace: FC<Props> = ({ createdAt, subscription }) => {
  return (
    <div>
      <Typography
        variant={Typographies.TITLE_MEDIUM}
        component="p"
        sx={{
          fontWeight: '400',
          maxWidth: '600px',
        }}
      >
        Your workspace was created on{' '}
        {DateService.format(new Date(createdAt), 'MMM d yyyy')}
      </Typography>

      <Typography
        variant={Typographies.TITLE_MEDIUM}
        component="p"
        sx={{
          fontWeight: '400',
          maxWidth: '600px',
        }}
      >
        {getStatusText(
          subscription.status,
          subscription.expired_at,
          subscription.subscription_name,
          subscription.pause_to,
        )}
      </Typography>
    </div>
  );
};

export default Workspace;
