import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';

import {
  getBillingPeriod,
  getTrialStatus,
  getWillBeChargedText,
} from '~/modules/settings/views/Subscription/SubscriptionInfo/helpers';
import { Typographies } from '~/theme/typography';
import { REGULARITY } from '~/types/product';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

import styles from './styles.module.scss';

type Props = {
  status: SUBSCRIPTION_STATUS;
  isTrial: boolean;
  billingPeriod: REGULARITY;
  subPrice: number;
  planName?: string;
  renewOn?: string;
  isShowStatus?: boolean;
};

const Table: FC<Props> = ({
  status,
  isTrial,
  billingPeriod,
  subPrice,
  planName,
  renewOn,
  isShowStatus,
}) => {
  return (
    <div className={styles.info__table}>
      <div className={styles.info__table_column}>
        {planName && (
          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.info__table_title}
          >
            Your Plan
          </Typography>
        )}
        {isShowStatus && (
          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.info__table_title}
          >
            Status
          </Typography>
        )}
        <Typography
          variant={Typographies.TITLE_SMALL}
          component="p"
          className={styles.info__table_title}
        >
          Trial
        </Typography>

        {renewOn && (
          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.info__table_title}
          >
            Renews on
          </Typography>
        )}

        <Typography
          variant={Typographies.TITLE_SMALL}
          component="p"
          className={styles.info__table_title}
        >
          Billing period
        </Typography>

        <Typography
          variant={Typographies.TITLE_SMALL}
          component="p"
          className={styles.info__table_title}
        >
          {[
            SUBSCRIPTION_STATUS.REDEMPTION,
            SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING,
            SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_FAILED,
          ].includes(status) && (
            <Icon icon="material-symbols:info-outline" width="18" />
          )}
          <span>Will be charged</span>
        </Typography>
      </div>

      <div className={styles.info__table_column}>
        {planName && (
          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.info__table_text}
          >
            {planName}
          </Typography>
        )}
        {isShowStatus && (
          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.info__table_text}
          >
            {status.split('_')[0]}
          </Typography>
        )}
        <Typography
          variant={Typographies.TITLE_SMALL}
          component="p"
          className={styles.info__table_text}
        >
          {getTrialStatus(status, isTrial)}
        </Typography>

        {renewOn && (
          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.info__table_text}
          >
            {renewOn}
          </Typography>
        )}

        <Typography
          variant={Typographies.TITLE_SMALL}
          component="p"
          className={styles.info__table_text}
        >
          {getBillingPeriod(status, billingPeriod)}
        </Typography>

        <Typography
          variant={Typographies.TITLE_SMALL}
          component="p"
          className={styles.info__table_text}
        >
          {getWillBeChargedText(status, subPrice)}
        </Typography>
      </div>
    </div>
  );
};

export default Table;
